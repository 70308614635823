import { Helmet } from "react-helmet";

const HtmlHelmet = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Gravity Always Wins</title>
  </Helmet>
);

export default HtmlHelmet;
