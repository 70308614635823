import styled from 'styled-components';

const HeaderElement = styled.header`
  align-items: center;
  background-color: #000;
  border-top: 1px solid rgba(255,255,255, 0.2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: center;
  position: fixed;
  top: 0;
  white-space: nowrap;
  width: 100vw;
  z-index: 1;
`;

const H1Element = styled.h1`
  color: #fff;
  font-size: 24px;
  font-weight: bold;
`;

const Header = () => (
  <HeaderElement role="heading" aria-level="1">
    <H1Element>
      Gravity Always Wins
    </H1Element>
  </HeaderElement>
);

export default Header;
