export const getInnerHeightCssValue = innerHeight => {
  let innerHeightValue = innerHeight;
  if (
    innerHeightValue === Infinity ||
    isNaN(innerHeightValue) ||
    typeof innerHeightValue !== "number"
  ) {
    innerHeightValue = 380;
  }
  return `${+innerHeightValue - (96 + 48 + 12 + 12 + 12) }px`;
};