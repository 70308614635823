import styled from 'styled-components';

import logo from '../../img/gravity-logo-min.svg';

const IconContainerElement = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;
const IconOuterElement = styled.div`
  height: 66vmin;
  position: relative;
  width: 66vmin;
`;

const Icon = () => (
  <IconContainerElement>
    <IconOuterElement>
      <img src={logo} alt="Gravity Always Wins logo" />
    </IconOuterElement>
  </IconContainerElement>
);

export default Icon;
