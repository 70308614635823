import React, { useState, useEffect } from 'react';

import Content from '../Content';
import Footer from '../Footer';
import Header from '../Header';
import HtmlHelmet from '../HtmlHelmet';
import Icon from '../Icon';

const App = () => {
  const [ windowInnerHeight, setWindowInnerHeight ] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => setWindowInnerHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <HtmlHelmet />
      <Header />
      <Content innerHeight={windowInnerHeight}>
        <Icon />
      </Content>
      <Footer />
    </>
  );
};

export default App;
