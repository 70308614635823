import { getYear } from '../../js/getYear';

import styled from 'styled-components';

const FooterElement = styled.footer`
  background-color: #000;
  border-bottom: 1px solid rgba(255,255,255, 0.2);
  bottom: 0;
  box-sizing: border-box;
  height: 48px;
  position: fixed;
  width: 100vw;
  z-index: 1;
`;
const FooterText = styled.div`
  align-items: center;
  color: rgba(255,255,255, 0.5);
  display: flex;
  font-size: 16px;
  height: 100%;
  justify-content: center;
`;

const Footer = () => (
  <FooterElement>
    <FooterText>{`© ${getYear()}`}</FooterText>
  </FooterElement>
);

export default Footer;
