import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getInnerHeightCssValue } from '../../js/getInnerHeightCssValue';

const ContentElement = styled.div`
  position: relative;
  top: 108px;
  width: 100vw;
`;

const Content = ({ children, innerHeight }) => (
  <ContentElement style={{ height: getInnerHeightCssValue(innerHeight) }}>
    { children }
  </ContentElement>
);

Content.defaultProps = {
  children: React.createElement('div'),
  innerHeight: window.innerHeight,
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  innerHeight: PropTypes.number.isRequired,
};

export default Content;
